<template>
    <section>
        <!-- Grafica Ventas y Pedidos dia a dia -->
        <div class="row mx-0 align-items-center mb-4 px-5 text-general f-18 f-600">
            Ventas y pedidos día a día
            <div class="col">
                <div class="row mx-5 justify-content-start border-bottom">
                    <div
                    v-for="(data, i) in meses"
                    :key="i" class="col-auto p-2 f-14"
                    :class="data.activo ? (mes == data.fecha ? 'cr-pointer border-b-red text-general-red' :'cr-pointer text-muted2') :'text-gris' "
                    @click="seleccionarMes(data)"
                    >
                        {{ data.mes }}
                    </div>
                </div>
            </div>
        </div>
        <div class="position-relative">
            <cargando v-if="loading1" />
            <chartBarras :datos="datos_diarios" :chart="chart_diario" />
        </div>
        <!-- Grafica Ventas y Pedidos dia a dia -->
        <!-- Grafica Ventas Mensuales -->
        <div class="row mx-0 px-5 text-general my-4 f-18 f-600">
            Ventas mensuales durante los últimos 12 meses
        </div>
        <div class="position-relative">
            <cargando v-if="loading2" />
            <chartBarras :datos="datos_mensual" :chart="chart_meses" />
        </div>
        <!-- Grafica Ventas Mensuales -->
        <div class="row mx-0 px-5 text-general f-18 f-600">
            Calificaciones de los pedidos de los últimos 12 meses
        </div>
        <!-- Calificaciones pedidos permisos 12 -->
        <div class="position-relative">
            <cargando v-if="loading3" />
            <chartBarras :datos="datos_ultimos" :chart="chart_ultimos" />
        </div>
        <!-- Calificaciones pedidos permisos 12 -->
    </section>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import echarts from 'echarts'
Vue.component('echarts',echarts);
let colorGeneral = getComputedStyle(document.documentElement) .getPropertyValue('--color-general')
let colorGeneral2 = getComputedStyle(document.documentElement) .getPropertyValue('--color-db')
import MetricasAdmin from "~/services/metricas_admin"
export default {
    props: {
        datos : {
            type: Boolean,
            default: true
        },
        tipoFecha : {
            type: Number,
            default: 1
        }
    },
    data(){
        return{
            loading1: true,
            loading2: true,
            loading3: true,
            mes: '',
            // tipoFecha: 1,
            meses: [],
            datos_mensual: [
                { nombre: 'Ventas totales', valor: '25.564.654', color: 'bg-gr-general', formato:1},
                { nombre: 'Ventas promedio al mes', valor: '234.500', color: 'bg-gr-pink', formato:1},
                { nombre: 'Pedidos totales', valor: '1.542', color: 'bg-gr-red', formato:2},
                { nombre: 'Pedidos promedio al mes', valor: '84', color: 'bg-gr-purple', formato:2}
            ],
            datos_diarios: [
                { nombre: 'Ventas totales', valor: '$25.564.654', color: 'bg-gr-red', formato:1 },
                { nombre: 'Ventas promedio al día', valor: '$234.500', color: 'bg-gr-general', formato:1 },
                { nombre: 'Día de mayor venta', valor: '$287.000', fecha: '12 de Noviembre', color: 'bg-dark', formato:1 }
            ],
            datos_ultimos: [
                { nombre: 'Mes con mejor calificiación', valor: '0', fecha: 'Ene. 2020', color: 'bg-gr-general', formato:3 },
                { nombre: 'Mes con peor calificiación', valor: '0', fecha: 'Oct. 2019', color: 'bg-gr-red', formato:3 }
            ],
            chart_meses: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#283b56'
                        }
                    }
                },
                legend: {
                    data:['Pedidos', 'Ventas']
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                    },
                    {
                        type: 'category',
                        boundaryGap: true,
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Valor',
                    },
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Pedidos',
                    }
                ],
                dataset:{
                    dimensions:['mes', 'total','cantidad'],
                    source: []
                },
                series: [
                    {
                        name: 'Ventas',
                        type: 'bar',
                        // xAxisIndex: 1,
                        yAxisIndex: 1,
                        itemStyle: {
                            color: colorGeneral2
                        },
                    },
                    {
                        name: 'Pedidos',
                        type: 'line',
                        symbol:'circle',
                        symbolSize: 10,
                        itemStyle: {
                            color: colorGeneral
                        },
                    }
                ]
            },

            chart_diario: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#283b56'
                        }
                    }
                },
                legend: {
                    data:['Pedidos', 'Ventas']
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                    },
                    {
                        type: 'category',
                        boundaryGap: true,
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Valor',
                    },
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Pedidos',
                    }
                ],
                dataset:{
                    dimensions:['dia', 'total','cantidad'],
                    source: []
                },
                series: [
                    {
                        name: 'Ventas',
                        type: 'bar',
                        // xAxisIndex: 1,
                        yAxisIndex: 1,
                        itemStyle: {
                            color: colorGeneral2
                        },
                    },
                    {
                        name: 'Pedidos',
                        type: 'line',
                        symbol:'circle',
                        symbolSize: 10,
                        itemStyle: {
                            color: colorGeneral
                        },
                    }
                ]
            },

            chart_ultimos: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#283b56'
                        }
                    }
                },
                legend: {
                    data:['Cantidad', 'Calificaciones']
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                    },
                    {
                        type: 'category',
                        boundaryGap: true,
                    }
                ],
                yAxis: [
                    {
                        name: 'Calificación',
                        type: 'value',
                        scale: true,
                    },
                    {
                        name: 'Cantidad',
                        type: 'value',
                        scale: true,
                    }
                ],
                dataset:{
                    dimensions:['mes', 'total','cantidad'],
                    source: []
                },
                series: [
                    {
                        name: 'Calificaciones',
                        type: 'bar',
                        // xAxisIndex: 1,
                        yAxisIndex: 1,
                        itemStyle: {
                            color: colorGeneral2
                        },
                    },
                    {
                        name: 'Cantidad',
                        type: 'line',
                        symbol:'circle',
                        symbolSize: 10,
                        itemStyle: {
                            color: colorGeneral
                        },
                    }
                ]
            },
            data_ventas_mensuales: {
                created: [],
                entregas: []
            },
            datos_ventas_mensuales: {
                created: {
                    total: null,
                    total_promedio: null,
                    cantidad: null,
                    cantidad_promedio: null,
                },
                entregas: {
                    total: null,
                    total_promedio: null,
                    cantidad: null,
                    cantidad_promedio: null,
                }
            },
            data_ventas_diarias: {
                created: [],
                entregas: []
            },
            datos_ventas_diarias: {
                created: {
                    total: null,
                    total_promedio: null,
                    dia_total: null,
                    dia_dia: null,
                },
                entregas: {
                    total: null,
                    total_promedio: null,
                    dia_total: null,
                    dia_dia: null,
                }
            },
            data_calificaciones_mensuales: {
                created: [],
                entregas: []
            },
            datos_calificaciones_mensuales: {
                created: {
                    min_mes: null,
                    min_total: null,
                    max_mes: null,
                    max_total: null,
                },
                entregas: {
                    min_mes: null,
                    min_total: null,
                    max_mes: null,
                    max_total: null,
                }
            },
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
        cedi(){
            return this.$route.params.id_cedis ?? this.id_cedis
        }
    },
    watch: {
        tipoFecha(){
            this.calcular_ventas_mensuales()
            this.calcular_ventas_diarias()
            this.calcular_calificaciones_mensuales()
        },
        // id_moneda(val){
        //     if(val == null) return
        //     this.ventas_mensuales()
        //     this.ventas_diarias()
        //     this.calificaciones_mensuales()
        // },
        // id_cedis(val){
        //     if(val == null) return
        //     this.ventas_mensuales()
        //     this.ventas_diarias()
        //     this.calificaciones_mensuales()
        // }
    },
    // mounted(){
    //     this.montar()
    // },
    methods: {
        cargando(){
            this.loading1 = true
            this.loading2 = true
            this.loading3 = true
        },
        async montar(){
            try {
                await this.toPromise(this.ventas_mensuales)
                await this.toPromise(this.ventas_diarias)
                await this.toPromise(this.calificaciones_mensuales)

            } catch (error){
                console.log(error);
            }
        },
        async ventas_mensuales(){
            try {
                let params = {
                    id_tienda: null,
                    id_moneda: this.id_moneda,
                    id_cedis: this.cedi

                }
                const { data } = await MetricasAdmin.ventas_mensuales(params)
                this.data_ventas_mensuales.created = data.data.pedidos_created
                this.data_ventas_mensuales.entregas = data.data.pedidos_entregas

                this.datos_ventas_mensuales.created.total = data.data.total_created
                this.datos_ventas_mensuales.created.total_promedio = data.data.total_promedio_created
                this.datos_ventas_mensuales.created.cantidad = data.data.cantidad_created
                this.datos_ventas_mensuales.created.cantidad_promedio = data.data.cantidad_promedio_created

                this.datos_ventas_mensuales.entregas.total = data.data.total_entregas
                this.datos_ventas_mensuales.entregas.total_promedio = data.data.total_promedio_entregas
                this.datos_ventas_mensuales.entregas.cantidad = data.data.cantidad_entregas
                this.datos_ventas_mensuales.entregas.cantidad_promedio = data.data.cantidad_promedio_entregas

                let pedidos = this.tipoFecha == 1 ? this.data_ventas_mensuales.entregas : this.data_ventas_mensuales.created
                this.meses = pedidos.map(p=>{
                    let m = {}
                    m = {
                        mes: p.mes,
                        activo: p.cantidad > 0,
                        fecha: p.fecha
                    }
                    this.mes = p.fecha
                    // if (p.cantidad > 0){
                    // }
                    return m
                })
                this.calcular_ventas_mensuales()
            } catch (error){
                this.error_catch(error)
            } finally{
                this.loading2 = false
            }
        },
        calcular_ventas_mensuales(){
            let pedidos = this.tipoFecha == 1 ? this.data_ventas_mensuales.entregas : this.data_ventas_mensuales.created
            let datos_ventas_mensuales = this.tipoFecha == 1 ? this.datos_ventas_mensuales.entregas : this.datos_ventas_mensuales.created
            this.chart_meses.dataset.source = pedidos

            this.datos_mensual[0].valor = datos_ventas_mensuales.total
            this.datos_mensual[1].valor = datos_ventas_mensuales.total_promedio
            this.datos_mensual[2].valor = datos_ventas_mensuales.cantidad
            this.datos_mensual[3].valor = datos_ventas_mensuales.cantidad_promedio
        },
        async ventas_diarias(){
            try {
                let params = {
                    id_tienda: null,
                    fecha: this.mes,
                    id_moneda: this.id_moneda,
                    id_cedis: this.cedi
                }
                const { data } = await MetricasAdmin.ventas_diarias(params)
                this.data_ventas_diarias.created = data.data.pedidos_created
                this.data_ventas_diarias.entregas = data.data.pedidos_entregas

                this.datos_ventas_diarias.created.total = data.data.total_created
                this.datos_ventas_diarias.created.total_promedio = data.data.total_promedio_created
                this.datos_ventas_diarias.created.dia_total = data.data.dia_max_created ? data.data.dia_max_created.total : 0
                this.datos_ventas_diarias.created.dia_dia = data.data.dia_max_created ? data.data.dia_max_created.dia : 'No registra'

                this.datos_ventas_diarias.entregas.total = data.data.total_entregas
                this.datos_ventas_diarias.entregas.total_promedio = data.data.total_promedio_entregas
                this.datos_ventas_diarias.entregas.dia_total = data.data.dia_max_entregas ? data.data.dia_max_entregas.total : 0
                this.datos_ventas_diarias.entregas.dia_dia = data.data.dia_max_entregas ? data.data.dia_max_entregas.dia : 'No registra'

                this.calcular_ventas_diarias()

            } catch (error){
                this.error_catch(error)
            } finally {
                this.loading1 = false
            }
        },
        calcular_ventas_diarias(){
            let pedidos = this.tipoFecha == 1 ? this.data_ventas_diarias.entregas : this.data_ventas_diarias.created
            let datos_ventas_diarias = this.tipoFecha == 1 ? this.datos_ventas_diarias.entregas : this.datos_ventas_diarias.created

            this.chart_diario.dataset.source = pedidos
            this.datos_diarios[0].valor = datos_ventas_diarias.total
            this.datos_diarios[1].valor = datos_ventas_diarias.total_promedio
            this.datos_diarios[2].valor = datos_ventas_diarias.dia_total
            this.datos_diarios[2].fecha = datos_ventas_diarias.dia_dia
        },
        async calificaciones_mensuales(){
            try {
                let params = {
                    id_tienda: null,
                    id_moneda: this.id_moneda,
                    id_cedis: this.cedi
                }
                const { data } = await MetricasAdmin.calificaciones_mensuales(params)

                this.data_calificaciones_mensuales.created = data.data.pedidos_created
                this.data_calificaciones_mensuales.entregas = data.data.pedidos_entregas

                this.datos_calificaciones_mensuales.created.max_total = data.data.max_created ? data.data.max_created.total : 0
                this.datos_calificaciones_mensuales.created.max_mes = data.data.max_created ? data.data.max_created.mes : 'No registra'
                this.datos_calificaciones_mensuales.created.min_total = data.data.min_created ? data.data.min_created.total : 0
                this.datos_calificaciones_mensuales.created.min_mes = data.data.min_created ? data.data.min_created.mes : 'No registra'

                this.datos_calificaciones_mensuales.entregas.max_total = data.data.max_entregas ? data.data.max_entregas.total : 0
                this.datos_calificaciones_mensuales.entregas.max_mes = data.data.max_entregas ? data.data.max_entregas.mes : 'No registra'
                this.datos_calificaciones_mensuales.entregas.min_total = data.data.min_entregas ? data.data.min_entregas.total : 0
                this.datos_calificaciones_mensuales.entregas.min_mes = data.data.min_entregas ? data.data.min_entregas.mes : 'No registra'


                this.calcular_calificaciones_mensuales()

            } catch (error){
                this.error_catch(error)
            } finally{
                this.loading3 = false
            }
        },
        calcular_calificaciones_mensuales(){
            let pedidos = this.tipoFecha == 1 ? this.data_calificaciones_mensuales.entregas : this.data_calificaciones_mensuales.created
            let datos_calificaciones_mensuales = this.tipoFecha == 1 ? this.datos_calificaciones_mensuales.entregas : this.datos_calificaciones_mensuales.created
            this.chart_ultimos.dataset.source = pedidos
            this.datos_ultimos[0].valor = datos_calificaciones_mensuales.max_total
            this.datos_ultimos[0].fecha = datos_calificaciones_mensuales.max_mes
            this.datos_ultimos[1].valor = datos_calificaciones_mensuales.min_total
            this.datos_ultimos[1].fecha = datos_calificaciones_mensuales.min_mes
        },
        seleccionarMes(data){
            data.activo ? this.mes = data.fecha : ''
            this.ventas_diarias()
        },

    }
}
</script>
